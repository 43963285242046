<template lang="pug">
div(:class="{'candidate-table-searching': $route.query.search}" data-test="candidate-list")
    app-table(
      v-model="activeItems"
      :headers="headers"
      :expanded.sync="expanded"
      single-expand=true
      :list="list"
      item-key="ID"
      show-select
      hide-default-footer
      multi-sort=false
      :sort-by.sync="sortModel.by"
      :sort-desc.sync="sortModel.desc"
      @update:sort-desc="updateSorting"
      :server-items-length="list.length"
      :single-select="false"
      :loading="loading"
      :mobile-breakpoint='NaN'
      hide-default-header
      class="app-table elevation-1 v-data-table theme--light"
    )
        <template v-slot:top>
          .pr-4.pl-4.pt-2.pb-2.d-flex.justify-end(v-if="isActiveLabels")
            portal-target(name="candidate-labels-list")
          v-divider(v-if="isActiveLabels")

          portal(to="candidates-filters")
            .actions-list
              refresh-button(@click="loadList" v-show="!activeItemsLength").mr-2.pb-1.pt-1
              div(v-if="activeItemsLength").app-table__selected-items-title {{ activeItemsLength}} items selected
              select-all(v-if="showSelectAll" @change="toggleSelectAllItems" :activeItemsCount="activeItemsLength" :itemsToSelectCount="pagination.count").mx-2.my-1
              v-spacer
              hold(:activeItems="activeItems" @change="updateActiveItemsHold").mr-2.pb-1.pt-1
              acceptBulk(:activeItems="activeItems").mr-2.pb-1.pt-1
              emailBulk(:activeItems="activeItems").mr-2.pb-1.pt-1
              bulk-assign-labels(:disabled="!activeItemsLength" :activeItems="activeItems").mr-2.pb-1.pt-1
              bulk-assign-labels(btnText="Unassign labels" mode="remove" :disabled="!activeItemsLength" :activeItems="activeItems").mr-2.pb-1.pt-1
              handleFailedCandidatesProvider(v-slot="handleFailed").pb-1.pt-1
                span.d-flex
                  v-dialog(:value="handleFailed.showing" @input="handleFailed.toggle" scrollable)
                    handleFailedBody(
                      :list="handleFailed.failedCandidates"
                      :error="handleFailed.error"
                      :totalSendCount="handleFailed.totalSendCount"
                      @input="handleFailed.toggle"
                    )
                  span
                    exchange(
                      :disabled="!activeItemsLength"
                      :activeItems="activeItems"
                      @send="handleFailed.check($event, activeItemsLength)"
                    ).mr-2
                  addRequests(:disabled="!activeItemsLength" :activeItems="activeItems" @send="handleFailed.check($event, activeItemsLength)").mr-2
              removeRequests(:disabled="!activeItemsLength" :activeItems="activeItems").mr-2.pb-1.pt-1
              try-to-reserve(:activeItems="activeItems" :disabled="!activeItemsLength").mr-2.pb-1.pt-1
              removeFromAcceptance(:disabled="!activeItemsLength" :activeItems="activeItems").mr-2.pb-1.pt-1
              sync(:disabled="!activeItemsLength" :activeItems="activeItems").mr-2.pb-1.pt-1
              copy(:activeItems="activeItems" :disabled="!activeItemsLength" :list="list" multiple).mr-2.pt-1.pb-1
              candidate-color(:activeItems="activeItems" :disabled="!activeItemsLength" :page="COLOR_PAGE").mr-2.pt-1.pb-1
          v-divider
        </template>

        template(v-slot:header="headerRow")
          header-row(:headerRow="headerRow")

        template(v-slot:item="{item, select, isSelected, index, expand, isExpanded}")
          candidate-row(
            :item="item"
            :isSelected="isSelected"
            :select="select"
            :index="index"
            :expand="expand"
            :isExpanded="isExpanded"
            :pagination="pagination"
            :primaryPagination="primaryPagination"
            :key="index"
            :activeItems="activeItems"
            :labels="labels"
            @selectedWithShiftkey="extendedSelection"
            @onGoToEdit="goToEdit"
            @click:payments="paymentsOpen"
          )

        template( v-slot:expanded-item="{ headers, item, expand}")
          td(:colspan="headers.length").pl-0.pr-0.app-table__expand
            candidate-item(
              :ID="item.ID"
              @close="hideExpandBy(expand)"
              ref="expand"
              :key='item.ID'
              @saved="updateTableDataBy(item, $event)"
            )

        template(v-slot:footer="{options}")
            .pagination(v-if="pagination")
                load-more-button(:loading="loading" :pagination="pagination" @loadMore="onLoadMoreClick")
                div.pagination__actions
                    v-pagination(
                        @input="paginationChange"
                        :disabled="loading"
                        :length="pagination.totalPages"
                        v-model="pagination.page"
                        :total-visible="8")

    payments-wrapper(ref="payments")
    portal-target(name="candidate-create")
</template>

<script>
import SortModel from "./core/models/candidatesSortModel"
import { FILTERS_ARGS, CANDIDATE_PAGE, CANDIDATES_HEADERS } from './core/candidates-const'
import copyMixin from "@/mixins/copy.mixin"
import itemModel from "./core/candidates-reservation-model"
import paginationMixin from '@/mixins/pagination.mixin'
import PaginationModel from "@/models/paginationModel"
import CandidateBroadcast from "./core/models/candidateBroadcast"
import CandidatePageMixin from "./core/candidates-page.mixin"

export default {
  mixins: [copyMixin, paginationMixin, CandidatePageMixin],

  data: () => ({
    headers: CANDIDATES_HEADERS,
  }),

  inject: ['svc'],

  methods: {
    async loadList({more = false}) {
      this._loadList({more, page: CANDIDATE_PAGE.WAIT})
    },
  },

  components: {
    appTable: () => import('@/components/global/GroupedTable.vue'),
    candidateRow: () => import('./components/candidates/CandidateRowTask.vue'),
    candidateItem: () => import('./components/item/CandidateItem.vue'),
    copy: () => import('./components/candidates/CandidatesCopy.vue'),
    candidateColor: () => import('./components/CandidateColor.vue'),
    headerRow: () => import('./components/candidates/CandidatesHeaders.vue'),
    exchange: () => import('./components/CandidatesExchange.vue'),
    addRequests: () => import('./components/CandidatesAddExamRequest.vue'),
    removeRequests: () => import('./components/CandidatesRemoveExamRequest.vue'),
    tryToReserve: () => import('./components/CandidatesTryToReserve.vue'),
    bulkAssignLabels: () => import('./components/candidates/CandidatesBulkAssignLabels.vue'),
    PaymentsProvider: () => import('./components/payments/CanidatesPaymentsProvider.vue'),
    Payments: () => import('./components/payments/CandidatesPayments.vue'),
    paymentsCancelProvider: () => import('./components/payments/CandidatesPaymentCancelProvider.vue'),
    emailBulk: () => import('./components/CandidatesEmail.vue'),
    mijnBulk: () => import('./components/CandidatesMIJNBulk.vue'),
    handleFailedCandidatesProvider: () => import('./components/CandidatesHandleFailedProvider'),
    handleFailedBody: () => import('./components/CandidatesHandleFailedBody.vue'),
    acceptBulk: () => import('./components/CandidateAcceptActivatorBulk.vue'),
    hold: () => import('./components/CandidateHold.vue'),
    refreshButton: () => import('./components/CandidatesRefreshButton.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
    removeFromAcceptance: () => import('./components/CandidatesRemoveFromAcceptance.vue'),
    selectAll: () => import('./components/CandidateSelectAll.vue'),
    sync: () => import('./components/CandidatesSync.vue'),
    paymentsWrapper: () => import('./components/payments/CandidatePaymentWrapper.vue'),
    loadMoreButton: () => import('@/components/widget/LoadMoreButton.vue'),
  },

  beforeRouteLeave(to, from, next) {
    this.broadcast.close()
    next()
  },

  provide: function () {
    return {
      markAsProcessed: this.markAsProcessed,
      loadList: this.loadList,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../src/app/admin/modules/candidates/assets/candidates.scss";
</style>
